import React from 'react'
import { graphql } from 'gatsby'
import PageBuilder from '../components/pageBuilder'
import SEO from '../components/seo'
import Helmet from 'react-helmet'

export const query = graphql`
  query GeschaeftsfelderPageQuery {
    geschaeftsfelderPage: sanityPage(slug: { current: { eq: "geschaeftsfelder" } }) {
      id
      title
      ...PageBuilder
    }
  }
`

const GeschaeftsfelderPage = props => {
  const { data, errors } = props
  const pageData = data && data.geschaeftsfelderPage
  return (
    <>
      <Helmet>
        <body className="is-page-geschaeftsfelder" />
      </Helmet>
      {pageData && pageData.title && <SEO title={pageData.title} />}
      {pageData && (
        <PageBuilder
          pageBuilder={pageData.pageBuilder}
          _rawPageBuilder={pageData._rawPageBuilder}
        />
      )}
    </>
  )
}

export default GeschaeftsfelderPage
